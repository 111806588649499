/**
 * Form Mixin
 *
 * This mixin provides common functionality for form components.
 */

const mixin = {
    inheritAttrs: false,
    props: {
        /**
         * The value passed into the form element.
         *
         * @var { Number, String, Boolean }
         */
        value: {
            type: [Number, String, Boolean],
            required: false,
            default: () => null,
        },

        /**
         * The name of the form element.
         *
         * @var String
         */
        name: {
            type: String,
            required: false,
            default: () => "",
        },

        /**
         * Determines whether the component is readonly.
         *
         * @var Boolean
         */
        readonly: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    data() {
        return {
            /**
             * The value of the form element.
             *
             * @var { Number, String, Boolean }
             */
            input: null,

            /**
             * The base classes for the component.
             *
             * @var String
             */
            baseClasses: "",

            /**
             * The available component states.
             *
             * @var Object
             */
            states: {
                /**
                 * The classes for when the component is in a default state.
                 */
                default: "",

                /**
                 * The classes for when the component is readonly.
                 */
                readonly: "",

                /**
                 * The classes for when the component has errors.
                 */
                error: "",
            },

            /**
             * The errors related to the form element.
             *
             * @var { Object }
             */
            errors: {},
        };
    },
    computed: {
        /**
         *  Returns the form component's attributes.
         *
         * @returns { Object }
         */
        attributes() {
            return {
                ...this.$attrs,
                class: this.classes,
            };
        },

        /**
         *  Returns the form component's listeners.
         *
         * @returns { Object }
         */
        listeners() {
            return {
                ...this.$listeners,
            };
        },

        /**
         * The current state of the component.
         *
         * @return String
         */
        state() {
            if (this.hasError) return "error";
            if (this.readonly) return "readonly";

            return "default";
        },

        /**
         * Returns true if the form component has a validation error.
         *
         * @return Boolean
         */
        hasError() {
            return Object.keys(this.errors).length > 0;
        },
    },
    watch: {
        /**
         * Sets the input value of the form component if the prop of "value" is changed.
         * Additionally, it clears any validation error that was present.
         *
         * @param { Number, String, Boolean }
         * @return void
         */
        value: {
            handler(value) {
                this.input = value;
                this.errors = {};
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        /**
         * Listens to the event bus for validation errors. This function also
         * sets the form component's errors upon receipt of an error.
         *
         * @return void
         */
        this.$eventBus.$on("error", (error) => {
            if (!error || !error.response) {
                return;
            }

            const { status } = error.response;
            const errors = {};

            if (!status === 422) {
                return;
            }

            if (error.response.data && error.response.data.errors) {
                this.errors = error.response.data.errors;

                Object.keys(error.response.data.errors).forEach((errorKey) => {
                    const formError = error.response.data.errors[errorKey];
                    if (errorKey === this.name) errors[errorKey] = formError;
                });
            }

            this.errors = errors;
        });
    },
    beforeDestroy() {
        /**
         * Stops listening to the event bus before the component
         * is destroyed to prevent memory leaks.
         */
        this.$eventBus.$off("validation-error");
    },
    methods: {
        /**
         * This emits the input event to outside of the component
         * so the v-model works.
         *
         * @return void
         */
        handleInput() {
            this.errors = [];
            if (!this.input || this.input.length === 0) this.$emit("input", null);
            else this.$emit("input", this.input);
        },
    },
};

export default mixin;
