<template>
    <v-form-select v-model="input" :options="selectOptions" :disabled="isLoading" :default-item-label="defaultItemLabel" />
</template>

<script>
import FormMixin from "@/components/form/form-mixin";

export default {
    mixins: [
        FormMixin,
    ],
    props: {
        keyColumn: {
            type: String,
            required: false,
            default: () => null,
        },
        labelColumn: {
            type: String,
            required: false,
            default: () => "name",
        },
        defaultItemLabel: {
            type: String,
            required: false,
            default: () => "All Results",
        },
        defaultItemValue: {
            type: [String, Number, Boolean],
            required: false,
            default: () => null,
        },
        module: {
            type: String,
            required: true,
        },
        query: {
            type: Object,
            required: false,
            default: () => ({ /* */ }),
        },
        options: {
            type: Object,
            required: false,
            default: () => ({ setItems: false }),
        },
    },
    data() {
        return {
            selectOptions: [{
                label: this.isLoading ? "Loading, please wait." : this.defaultItemLabel,
                value: this.defaultItemValue,
            }],
        };
    },
    computed: {
        fetchKey() {
            if (this.keyColumn) return this.keyColumn;
            return this.$store.getters[`${this.module}/getItemKey`];
        },
        fetchQuery() {
            return {
                paginated: false,
                ...this.query,
            };
        },
        fetchOptions() {
            return this.options;
        },
    },
    watch: {
        fetchQuery: {
            handler(fetchQuery) {
                this.toggleLoading();

                this.selectOptions = [{
                    label: this.isLoading ? "Loading, please wait." : this.defaultItemLabel,
                    value: this.defaultItemValue,
                }];

                this.$store.dispatch(`${this.module}/fetchAllItems`, fetchQuery, this.fetchOptions)
                    .then((response) => {
                        this.selectOptions.push(...response.data.map((item) => ({
                            label: item[this.labelColumn],
                            value: item[this.fetchKey],
                        })));
                    })
                    .finally(() => {
                        this.toggleLoading();
                    });
            },
            immediate: true,
            deep: true,
        },
        input: {
            handler(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>
